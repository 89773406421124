import React from "react";

const FourOhFour = () => {
  return (
    <div className="fourOhFour">
      <h1>Oh nooooooo!</h1>
      <a href="/">Voltar para home.</a>
    </div>
  );
};

export default FourOhFour;
